<template>
  <div>
    <div style="padding: 10px">
      <el-button type="primary" size="mini" @click="importShow">导入商品数据</el-button>
      <el-button type="warning" size="mini" @click="selDel">批量删除</el-button>
      <el-button type="warning" size="mini" @click="selDelShow">批量自定义删除</el-button>
      <!-- <el-button type="danger" size="mini">全部删除</el-button> -->
    </div>

    <el-divider content-position="left">{{ DataInfo.name }}</el-divider>

    <div class="disListDiv">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        height="600"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>

        <el-table-column prop="id" label="id" width="80" align="center">
        </el-table-column>

        <el-table-column label="图片" width="150" align="center">
          <template slot-scope="scope">
            <img
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${scope.row.goods_code}.jpg?x-oss-process=style/440`"
              class="mr-3"
              alt="..."
            />
          </template>
        </el-table-column>

        <el-table-column
          prop="goods_code"
          label="产品编码"
          show-overflow-tooltip
          width="250"
          align="center"
        >
        </el-table-column>

        <el-table-column prop="item_name" label="中文产品名" align="center">
          <template slot-scope="scope">{{ scope.row.item_name }}</template>
        </el-table-column>

        <el-table-column prop="base_price" label="配送价" align="center">
        </el-table-column>

        <el-table-column prop="sale_price" label="零售价" align="center">
        </el-table-column>



      </el-table>
    </div>
<!-- 批量自定义删除商品 -->
    <el-drawer
          title="批量自定义删除商品"
          :visible.sync="delDrawer"
          :direction="direction"
          :before-close="handleClose"
        >
          <div style="width: 90%; margin: 0 auto">
            请输入属于当前的商品，示例：
            <div style="font-size: 18px; color: #889aa4;font-weight: 600;margin-left: 40px;margin-top: 10px;">
              <span style="width: 100%;">6941196077497</span>
              <span style="width: 100%;">6941196071990</span>
              <span style="width: 100%;">6941700671135</span>
            </div>
            <el-input
              type="textarea"
              placeholder="输入产品SKU,每行一个SKU，如示例"
              rows="15"
              v-model="delGoodsCodeS"
            ></el-input>

            <el-button type="danger" style="margin: 10px" @click="batchDelGoodsSubmit"
              >删除</el-button
            >
          </div>
        </el-drawer>

    <!-- 导入产品 -->
    <el-drawer
      title="导入产品"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="width: 90%; margin: 0 auto">
        <!--  -->
        <template>
          {{ goodsCodeSConfig.demoTitle }}示例: {{ goodsCodeSConfig.demoText }}
        <div style="font-size: 18px; color: #889aa4;font-weight: 600;margin-left: 40px;margin-top: 10px;">
          <pre>{{ goodsCodeSConfig.tip }}</pre>
        </div>
        <el-input
          type="textarea"
          :placeholder="goodsCodeSConfig.placeholder"
          rows="15"
          v-model="goodsCodeS"
        ></el-input>
        </template>
        <!--  -->
        <el-button type="success" style="margin: 10px" @click="implodeSubmit">提交</el-button>
      </div>
    </el-drawer>

    <!-- <el-dialog width="35%" title="绑定分类商品" :close-on-click-modal="false" :visible.sync="showAddTypeGoods">
      <el-input placeholder="输入分类" v-model.number.trim="goodsType"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddTypeGoods = false">取 消</el-button>
        <el-button @click="handleAddTypeGoods">添加</el-button>
      </span>
    </el-dialog> -->


  </div>
</template>

<script>

import themezoneApi from "@/api/admin/themezone";


export default {
  data() {
    return {
      import_type:"",
      import_type_title1:"导入",
      goodsCodeS: "",
      goodsCodeSConfig:{
        demoTitle:this.import_type_title1,
        demoText:"",
        title:"",
        tip:"",
        placeholder:""
      },
      DataInfo: {},
      infoId: 0,
      drawer: false,
      // 批量自定义删除
      delDrawer:false,
      delGoodsCodeS:"",
      editDrawer:false,
      direction: "rtl",
      tableData: [],
      multipleSelection: [],
      form:{
        id:0,
        goods_code:"",
        item_name:"",
        base_price:0,
        sale_price:0,
        dis_price:0,
        fixed_price:0
      },
      showAddTypeGoods:false,
      goodsType:""
    };
  },
  created() {
    this.getThemeZoneId(); //获取到ID
    this.getData(); //获取到数据
    this.getThemeZoneInfo(); //获取详情
    //设置默认的提示
    this.setDefaultTipText()
  },
  watch:{


  },
  methods: {
    // 设置默认提示
    setDefaultTipText(){
      this.goodsCodeSConfig.demoTitle=this.import_type_title1
      this.goodsCodeSConfig.demoText="SKU"
      this.goodsCodeSConfig.title= this.import_type_title1
      this.goodsCodeSConfig.tip = "6941196077497\n6941196071990\n6941700671135"
      this.goodsCodeSConfig.placeholder="输入产品SKU,每行一个SKU，如示例"
    },


    //导入商品提交
    implodeSubmit() {
      // 提交表单数据
      let postData = {goods_code: [] };
      postData.goods_code = this.goodsCodeS.split("\n");
      // 数据验证
      if(this.goodsCodeS===""){
        this.$message.error("请输入数据");
        return false
      }
      this.$axios
        .post("/a1/theme/importList/" + this.infoId, postData)
        .then((res) => {
          this.$message.success("导入成功");
          this.getData();
          this.drawer = false;
        }).catch((err)=>{
          this.$alert(err.response.data.message, {
            confirmButtonText: '确定',
          });
        });
    },
    //显示导入的界面
    importShow() {
      this.goodsCodeS = "";
      this.drawer = true;
    },
    // 批量自定义删除界面
    selDelShow(){
      this.delGoodsCodeS = ""
      this.delDrawer = true;
    },
    // 批量自定义删除提交
    batchDelGoodsSubmit(){
      // 提交表单数据
      let postData = { theme_zone_id:0,goods_code: [] };
      postData.theme_zone_id = this.infoId
      postData.goods_code = this.delGoodsCodeS.split("\n");
      // 数据验证
      if(postData.theme_zone_id ===0){
        this.$message.error("数据有误，请刷新界面");
        return false
      }
      if(this.delGoodsCodeS===""){
        this.$message.error("请输入数据");
        return false
      }
      this.$axios
        .delete("/a1/theme/batchDelGoods", {data:postData})
        .then((res) => {
          this.$message.success("删除成功");
          this.getData();
          this.delDrawer = false;
        }).catch((err)=>{
          this.$alert(err.response.data.message, {
            confirmButtonText: '确定',
          });
        });
    },
    //
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 直接关闭
    handleNowClose(done) {
      done();
    },
    //批量删除
    selDel() {
      if (this.multipleSelection.length > 0) {
        let ids = { ids: [] };
        let goodsPush = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let theGoodsCode = this.multipleSelection[i].id;
          goodsPush.push(theGoodsCode);
        }
        ids.ids = goodsPush;
        this.$axios
          .delete("/a1/theme/delGoodsList", {
            data: ids,
          })
          .then((res) => {
            this.$message.success("删除成功");
            this.getData();
          });
      } else {
        this.$message.info("请选择商品");
      }
    },
    //获取活动详情
    getThemeZoneInfo() {
      themezoneApi.getDetail(this.infoId).then((res) => {
        this.DataInfo = res.data.data
      });
    },
    getThemeZoneId() {
      var query = this.$route.query;
      this.infoId = query.id;
    },
    getData() {
      this.$axios.get("/a1/theme/GetGoods/" + this.infoId).then((res) => {
        this.tableData = res.data.data;
      });
    },
    //选中操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑

    // handleAddTypeGoods(){

    //   if (this.goodsType <= 0 ) {
    //     this.$message.info("输入分类有误")
    //     return false
    //   }

    //   this.$axios.post("/a1/theme/import/goodstype", {goods_type:this.goodsType,theme_id:+this.infoId}).then(() => {
    //     this.$message.success("添加成功")
    //     this.showAddTypeGoods = false
    //     this.getData();
    //   }).catch(err => {
    //     this.$message.error(err.response.data.message)
    //   })
    // }

  },
};
</script>

<style>
.disListDiv {
  border: 1px solid #889aa4;
  padding: 10px;
  margin: 0 auto;
  width: 98%;
  border-radius: 8px;
}
</style>
